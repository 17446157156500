<template>
	<div class="insightBox">
		<div class="leftChildNav" :class="showLeft ? '' : 'hide'">
			<div class="topTitle">{{ title[activeR] }}</div>
			<!-- <div class="fixedLeft" @click="changeFixed">
				<img v-if="fixedLeft" src="../assets/image/fixed_active.png" alt />
				<img v-else src="../assets/image/fixed.png" alt />
			</div> -->
			<Menu :active-name="activeMenu" @on-select="selectRouter" :accordion="true" :open-names="openName"
				@on-open-change="subMenuClick">
				<template v-for="(item, index) in route[activeR]">
					<Submenu :name="item.title" v-if="item.children" :key="index"
						:class="{ 'sub-menu-active': item.path == activeMenu }">
						<template slot="title" class="topNav">
							<Icon :custom="item.icon" size="20" />
							{{ item.title }}
						</template>
						<template v-for="(item, index) in item.children">
							<MenuItem :key="index" v-if="!item.children" :name="item.path">{{ item.title }}</MenuItem>
							<Submenu :key="index" v-else :name="item.path">
								<template slot="title">
									<Icon :custom="item.icon" size="20" />

									{{ item.title }}
								</template>
								<MenuItem :key="index" v-for="(item, index) in item.children" :name="item.path">
								{{ item.title }}</MenuItem>
							</Submenu>
						</template>
					</Submenu>
					<MenuItem v-else :key="index" :name="item.path">
					<!-- <img :src="item.icon" class="routerIcon"> -->
					<Icon :custom="item.icon" size="20" />
					{{ item.title }}
					</MenuItem>
				</template>
			</Menu>
		</div>
		<div class="rightContent">
			<img class="openNav" v-if="showLeft" src="../assets/image/shouqi.png" @click="changeState({
				prop: 'showLeft',
				value: false
			})" />
			<img class="closeNav" v-if="!showLeft" @click="changeState({
				prop: 'showLeft',
				value: true
			})" src="../assets/image/zhankai.png" />

			<keep-alive  include="taskDetail">
				<router-view />
			</keep-alive>
				<!-- <router-view v-else /> -->


		</div>
	</div>
</template>

<script>
import {
	mapState,
	mapMutations
} from 'vuex'
export default {
	name: 'home',
	data() {
		return {
			routerChildren: [],

			activeR: 'Pledge',
			title: {
				Pledge: '房屋智能估价',
				Analysis: '一键看项目',
			},
			route: {
				Pledge: [
					{
						path: '',
						title: '房屋智能估价（单套）',
						// icon: require('@/assets/image/eIcon1.png'),
						icon: 'iconfont icon-a-fangwuzhinenggujiadantao',
						children: [
							// {
							// 	path: '/Empowerment/pledge/houseProperty',
							// 	title: '快速估价（旧）'
							// },
							{
								path: '/Empowerment/pledge/houseProperty/rapidValuation',
								title: '快速估价'
							},
							{
								path: '/Empowerment/pledge/houseProperty/recordValuation',
								title: '估价记录'
							},
						]
					},
					// {
					// 	path: '',
					// 	title: '房屋智能估价（批量）(旧)',
					// 	icon: ' iconfont icon-a-fangwuzhinenggujiapiliang',
					// 	children: [

					// 		{
					// 			path: '/Empowerment/pledge/pledgeManage/pledgeManage',
					// 			title: '首页'
					// 		},

					// 		{
					// 			path: '/Empowerment/pledge/pledgeManage/taskManage',
					// 			title: '任务管理'
					// 		},
					// 		{
					// 			path: '/Empowerment/pledge/pledgeManage/pledgeSearch',
					// 			title: '估价查询'
					// 		},
					// 		{
					// 			path: '/Empowerment/pledge/pledgeManage/result',
					// 			title: '估价结果'
					// 		}
					// 	]
					// },
					{
						path: '',
						title: '房屋智能估价（批量）',
						icon: ' iconfont icon-a-fangwuzhinenggujiapiliang',
						children: [
							{
								path: '/Empowerment/pledge/pledgeManage/taskManageNew',
								title: '任务管理'
							},
							{
								path: '/Empowerment/pledge/pledgeManage/taskRecord',
								title: '估价记录'
							},
						]
					}
				],
				Analysis: [{
					path: '/Empowerment/Analysis/Home',
					icon: 'iconfont icon-shouye',
					title: '首页'
				},
					// {
					// 	path: '/Empowerment/Analysis/Detail',
					// 	icon: require('../assets/image/icon7.png'),
					// 	title: '项目详情'
					// }
				],
			}
		};
	},
	computed: {
		...mapState(['showLeft', "fixedLeft"]),
		activeMenu() {
			// let route = this.$route;
			// let path = route.path;
			// // let activeMenu = this.route(this.activeR);
			// return path;
			let route = this.$route;
			let path = route.path;
			let meta = route.meta;
			if (meta && meta.from) {
				path = meta.from;
			}
			// let activeMenu = this.route(this.activeR);
			return path;
		},
		openName() {
			const route = this.$route;
			// const title = route.meta.title;
			const routerList = [route.meta.fName]
			return routerList;

		},

	},
	watch: {
		$route(to,from) {
			this.changeLeft(to);
			if (from.path === '/Empowerment/pledge/pledgeManage/taskManageNew') {
				to.meta.keepAlive = false;
			}else {
				to.meta.keepAlive = true;
			}
		},
	},
	methods: {
		...mapMutations(['changeState', 'changeFixed']),
		selectRouter(data) {
			if (this.$route.path === data) return;
			if (data.indexOf('noPage') > -1) {
				return
			}
			this.$router.push(data).catch(err => err);
		},
		subMenuClick(arr) {
			// if (arr.length) {
			// 	const data = arr.pop().replace('submenu', '')
			// 	data && this.$router.push(data);
			// }
		},
		changeLeft(router) {
			// 用path来判断属于哪个二级路由
			if (router.path.indexOf('/Empowerment/pledge/') > -1) {
				this.activeR = 'Pledge';
			} else if (router.path.indexOf('/Empowerment/Analysis/') > -1) {
				this.activeR = 'Analysis';
			}
		}
	},
	mounted() {
		this.changeLeft(this.$route);
		this.$store.dispatch('initPublicData');
	}
};
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";

@import "@/assets/css/common.scss";
</style>
